<template>
   <div>
       <el-card shadow="never">
           <template #header>
               <div class="card-header" style="text-align:left">
                    <el-col :span = "4" class="hidden-md-and-down">
                    <span>编辑优惠券信息</span>
                    </el-col>
                </div>
           </template>
           <div class="content">
                <el-form :model="couponinfo" :rules="rules" ref="couponinfo" label-width="200px" class="pdtform" size="small">
                    <el-form-item label="优惠券名称" prop="CouponName">
                        <el-input v-model="couponinfo.CouponName" class="input-size"></el-input>
                    </el-form-item>
                    <el-form-item  label="领券方式" prop="Type">
                        <el-select v-model="couponinfo.Type" class="input-size" placeholder="请选择" :disabled='couponinfo.ID?true:false'>
                            <el-option label="指定送" value="1"></el-option>
                            <el-option label="领取送" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="优惠券类型" prop="CouponType">
                        <el-select v-model="couponinfo.CouponType" class="input-size" placeholder="请选择" @change="ChangeCouponType" :disabled='couponinfo.ID?true:false'>
                            <el-option label="首期一元优惠券" value="3"></el-option>
                            <el-option label="首期款租金券" value="1"></el-option>
                            <el-option label="延长还机时间券" value="2"></el-option>
                            <el-option label="满减优惠券" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="优惠券品类" prop="CouponType" v-if="couponinfo.CouponType == 1" :disabled='couponinfo.ID?true:false'>
                        <el-radio-group v-model="couponinfo.UseType" @change="SwitchCouponValue">
                            <el-radio label="1">抵扣券</el-radio>
                            <el-radio label="2">折扣券</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item  :label="CouponValue"  prop="CouponType" required>
                        <div v-if="couponinfo.CouponType == 2">
                            延长 <el-input-number v-model="couponinfo.CouponValue" class="input-size" placeholder="优惠券面额" :controls='false'></el-input-number> 天
                        </div>
                        <div v-else>
                            <el-input-number v-model="couponinfo.CouponValue" :placeholder='CouponValue' class="input-size" :controls='false'></el-input-number> {{unit}}
                        </div>
                    </el-form-item>
                    <el-form-item label="使用条件" prop="Condition" v-if="couponinfo.CouponType != 3" required>
                        <div v-if="couponinfo.CouponType == 1 || couponinfo.CouponType == 4">
                        <el-select v-model="couponinfo.Condition">
                            <el-option label="签约价值" value="1"></el-option>
                            <el-option label="月租金" value="2"></el-option>
                        </el-select> 满 
                        <el-input-number v-model="couponinfo.ConditionValue" class="input-size" :controls='false'></el-input-number> 元可使用
                        </div>
                        <div v-if="couponinfo.CouponType == 2 ">
                            用户租满<el-input-number v-model="couponinfo.ConditionValue" class="input-size" :controls='false'></el-input-number> 天可使用
                        </div>
                    </el-form-item>
                    <el-form-item label="发放有效期" v-if="couponinfo.Type == 2 " required>
                        <el-col :span="11">
                        <el-form-item prop="StartDTime" style="margin-right:5px;">
                            <el-date-picker v-model="couponinfo.StartDTime" type="datetime" placeholder="发放优惠券开始时间"></el-date-picker>
                        </el-form-item>
                        </el-col>
                        <el-col :span='2'>至</el-col> 
                        <el-col :span="11">
                            <el-form-item prop="EndDTime" style="margin-left:5px;">
                                <el-date-picker v-model="couponinfo.EndDTime" type="datetime" placeholder="发放优惠券结束时间"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="使用有效期" v-if="couponinfo.CouponType != 3" prop="UseTimeType">
                        <el-radio-group v-model="couponinfo.UseTimeType">
                            <el-radio label="1">相对期限</el-radio>
                            <el-radio label="2">固定期限</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item >
                        <div v-if="couponinfo.UseTimeType == 1">
                            自领取之日起 <el-input-number v-model="couponinfo.UseTime" class="input-size" prop='UseTime' :controls='false'></el-input-number> 天内可使用
                        </div>
                        <div v-if="couponinfo.UseTimeType == 2">
                            <el-date-picker v-model="couponinfo.AvaliableDTime" type="datetime" placeholder="优惠券有效开始时间" :required='couponinfo.UseTimeType==2?true:false' prop='AvaliableDTime'></el-date-picker>
                            至 
                            <el-date-picker v-model="couponinfo.ExpireDTime" type="datetime" placeholder="优惠券有效结束时间" :required='couponinfo.UseTimeType==2?true:false' prop='ExpireDTime'></el-date-picker>
                        </div>
                    </el-form-item>
                    <!--
                    <el-form-item  label="发放数量" prop="CouponCount">
                        <el-input v-model="couponinfo.CouponCount" class="input-size"></el-input> 张
                    </el-form-item>-->
                    <el-form-item  label="领取次数"  prop="RecevieCount">
                        <el-input-number v-model="couponinfo.RecevieCount"  class="input-size" :disabled="couponinfo.Type == 1?true:false" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="用户行为" prop="UseCondition" required v-if="couponinfo.CouponType == 2">
                        <el-checkbox-group v-model="couponinfo.UseCondition" @change="CheckTimeLimit">
                            <el-checkbox label="1">用户下单可使用</el-checkbox>
                            <el-checkbox label="2">用户在租订单可使用</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="该券是否与其他优惠券互斥" required v-if="couponinfo.CouponType == 2">
                        <el-radio-group v-model="couponinfo.TypeOneMutual">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="该券是否与其他延期券互斥" required v-if="couponinfo.CouponType == 2">
                        <el-radio-group v-model="couponinfo.TypeTwoMutual">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="限制哪些时间创建订单可用" v-if="showtimelimit && couponinfo.CouponType == 2">
                        <el-radio-group v-model="couponinfo.TimeLimit">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="couponinfo.TimeLimit == 1 && couponinfo.CouponType == 2 && showtimelimit">
                        <el-date-picker v-model="couponinfo.LimitStart" type="datetime" placeholder="订单创建开始时间" style="margin-right:10px;"></el-date-picker>
                        <el-date-picker v-model="couponinfo.LimitEnd" type="datetime" placeholder="订单创建结束时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="领取渠道">
                        <el-checkbox-group v-model="couponinfo.Channel" disabled>
                            <el-checkbox label="1">支付宝小程序</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="使用渠道">
                        <el-checkbox-group v-model="couponinfo.UseChannel" disabled>
                            <el-checkbox label="1">支付宝小程序</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="手机号导入" v-if="couponinfo.Type == 1">
                        <el-col :span="11">
                        <el-upload :action="fileupload" :limit="1" :file-list="fileList" :on-change="handleChange" :on-success="UploadSuccess" >
                            <el-button @click="UploadMobile" type="primary" :disabled='couponinfo.ID?true:false'>点击上传</el-button>
                            
                        </el-upload>
                        <el-tag type="primary" size="small">请上传手机号txt文件</el-tag>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="使用范围">
                        <el-radio-group v-model="couponinfo.Range">
                            <el-radio label="1">全品</el-radio>
                            <el-radio label="2">限品</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="couponinfo.Range == 2">
                        <el-button @click="AddPlan" type="primary">添加商品</el-button>
                    </el-form-item>
                    <el-form-item v-if="couponinfo.Range == 2">
                        <el-table :data="couponinfo.pdtdata" size="small">
                            <el-table-column type="selection"></el-table-column>
                            <el-table-column label="套餐编码" prop="PlanCode"></el-table-column>
                            <el-table-column label="品类" prop="Category"></el-table-column>
                            <el-table-column label="品牌" prop="Brand"></el-table-column>
                            <el-table-column label="型号" prop="Model"></el-table-column>
                            <el-table-column label="套餐名称" prop="PlanTitle"></el-table-column>
                            <el-table-column label="状态" prop="Status">
                                <template #default="scope">
                                    <span v-if="scope.row.Status == 0">已下架</span>
                                    <span v-if="scope.row.Status == 1">上架中</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template #default="scope">
                                    <el-button type="text" size="small" @click="Remove(scope)">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item label="规则说明">
                        <el-input type="textarea" v-model="couponinfo.Remark" class="input-size" rows="5"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('couponinfo')">保存</el-button>
                        <el-button @click="back">返回</el-button>
                    </el-form-item>
                </el-form>
           </div>
       </el-card>
       <el-dialog v-model="addplan" title="添加套餐" width="70%">
           <el-cascader :options="option" 
                v-model="category" 
                placeholder="选择品类" 
                clearable
                style="margin-right:10px;width:120px" 
                :show-all-levels="false"
                :props="props"
                @change="SetBrand"
                size="small"
            ></el-cascader>
            <el-select v-model="brand" size="small" placeholder="选择品牌" filterable style="margin-right:10px;width:120px" clearable :disabled='category?false:true' @change="SetModel">
                <el-option v-for="brand in brands"
                    :key="brand.ID"
                    :label="brand.Brand"
                    :value="brand.ID"></el-option>
            </el-select>
            <el-select v-model="model" size="small" placeholder="选择型号" filterable style="margin-right:10px;width:120px" clearable :disabled='brand?false:true' @visible-change="GetModel">
                <el-option v-for="model in models"
                    :key="model.ID"
                    :label="model.Model"
                    :value="model.ID"></el-option>
            </el-select>
            <el-select v-model="pdt" size="small" placeholder="选择商品" filterable style="margin-right:10px;width:120px" clearable :disabled='model?false:true' @visible-change="GetPdt">
                <el-option v-for="pdt in pdts"
                    :key="pdt.ID"
                    :label="pdt.PdtName"
                    :value="pdt.ID"></el-option>
            </el-select>
            <el-input v-model="keyword" size="small" placeholder="请输入商品编码/套餐编码/套餐标题" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px"></el-input>
            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
            <el-table :data="tabledata" size="small" style="margin-top:15px;" @select="handleSelection" @select-all="handleSelectAll">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="套餐编码" prop="PlanCode"></el-table-column>
                <el-table-column label="品类" prop="Category"></el-table-column>
                <el-table-column label="品牌" prop="Brand"></el-table-column>
                <el-table-column label="型号" prop="Model"></el-table-column>
                <el-table-column label="套餐名称" prop="PlanTitle"></el-table-column>
                <el-table-column label="状态" prop="Status">
                    <template #default="scope">
                        <span v-if="scope.row.Status == 0">已下架</span>
                        <span v-if="scope.row.Status == 1">上架中</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                layout="total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            <template #footer>
                <el-button type="primary" @click="SaveSel" size="small">确定</el-button>
                <el-button @click="CloseSel" size="small">取消</el-button>
            </template>
       </el-dialog>
    </div> 
</template>
<style scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pdtform >>> .input-size {
    width: 20em;
}
</style>
<script>
import constant from "@/constant"
export default {
    data(){
        return {
            curpage:1,
            pagesize:30,
            totalcount:'',
            showtimelimit:false,
            fileupload:constant.fileupload,
            fileList:[],
            addplan:false,
            unit:'元',
            CouponValue:'优惠券面值',
            couponinfo:{
                ID:'',
                CouponName:'',
                Type:'2',
                CouponType:'1',
                UseType:'1',
                CouponValue:'',
                Condition: '1',
                ConditionValue:'',
                AvaliableDTime:'',
                ExpireDTime:'',
                UseTimeType:'1',
                StartDTime:'',
                EndDTime:'',
                CouponCount:'',
                RecevieCount:'',
                Channel:['1'],
                UseChannel:['1'],
                Range:'1',
                UseCondition:[],
                TypeOneMutual:'2',
                TypeTwoMutual:'2',
                TimeLimit:'2',
                LimitStart:'',
                LimitEnd:'',
                pdtdata:[],
                selectid:[],
                UseTime:'',
                Remark:'',
                filemd5:'',
                
            },
            props:{
                label:'label',
                value:'value',
                disabled:'Disabled'
            },
            category:'',
            brand:'',
            model:'',
            pdt:'',
            categorys:[],
            brands:[],
            models:[],
            pdts:[],
            keyword:'',
            option:[],
            tabledata:[],
            selectid:[],
            ids:[],
            rules:{
                CouponName:[{
                    required:true,
                    message:'请填写优惠券名',
                    trigger:'blur'
                }],
                CouponType:[{
                    required:true,
                    message:'请填写优惠券面额',
                    trigger:'blur'
                }],
                AvaliableDTime:[{
                    required:true,
                    message:'请选择发放时间',
                    trigger:'blur'
                }],
                ExpireDTime:[{
                    required:true,
                    message:'请选择发放时间',
                    trigger:'blur'
                }],
                StartDTime:[{
                    required:true,
                    message:'请选择创建订单开始时间',
                    trigger:'blur'
                }],
                EndDTime:[{
                    required:true,
                    message:'请选择创建订单结束时间',
                    trigger:'blur'
                }]
            }
        }
    },
    methods:{
         handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        UploadSuccess(response){
            console.log(response);
            if(response.msg == 'OK'){
                this.couponinfo.filemd5 = response.md5;
            }
        },
        CheckTimeLimit(val){
            console.log(val);
            if(val.indexOf('2') != -1)
                this.showtimelimit = true;
            else
                this.showtimelimit = false;
        },
        submitForm(){
            this.$refs['couponinfo'].validate((valid)=>{
                if(valid){
                    if(this.couponinfo.CouponType != 3){
                        if(this.couponinfo.Range == 2 && this.couponinfo.pdtdata.length == 0){
                            this.$message.error("请选定套餐");
                            return false;
                        }
                        if(this.couponinfo.UseTimeType == 2){
                            if(!this.couponinfo.StartDTime || !this.couponinfo.EndDTime){
                                this.$message.error("请填写有效时间区间");
                                return false;
                            }
                        }
                        if(this.couponinfo.UseTimeType == 1){
                            if(!this.couponinfo.UseTime){
                                this.$message.error("请填写时限");
                            }
                        }
                        if(this.couponinfo.Type == 2){
                            if(!this.couponinfo.RecevieCount){
                                this.$message.error("请填写领取次数");
                                return false;
                            }
                        }
                        if(this.couponinfo.TimeLimit == 1){
                            if(!this.couponinfo.LimitStart || !this.couponinfo.LimitEnd){
                                this.$message.error("请填写限定的时间区间");
                                return false;
                            }
                        }
                        console.log(this.couponinfo);
                    }
                    this.axios.post(constant.save_coupon,this.couponinfo,{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.$message.success('操作成功');
                            this.$router.push({path:'/coupon'});
                        }
                    })
                }else{
                    return false;
                }
            });
        },
        SaveSel(){
            //this.couponinfo.pdtdata = this.ids;
            this.couponinfo.selectid = this.selectid;
            console.log(this.couponinfo.pdtdata);
            this.addplan = false;
        },
        handleSelection(val,row){
            console.log(val);
            let id = row.ID;
            if(this.selectid.indexOf(id) == -1){
                this.selectid.push(id);
                this.couponinfo.pdtdata.push(row);
            }
            console.log(this.couponinfo.pdtdata);
        },
        handleSelectAll(val){
            console.log(val);
            for(let i = 0;i<val.length;i ++){
                this.couponinfo.pdtdata.push(val[i]);
                let id = val[i].ID;
                console.log(id);
                this.selectid.push(id);
            }
            console.log(this.selectid);
            console.log(this.couponinfo.pdtdata);
        },
        CloseSel(){
            this.addplan = false;
        },
        SwitchCouponValue(){
            console.log(this.couponinfo.UseType)
            if(this.couponinfo.UseType == '1'){
                this.CouponValue = '优惠券面值';
                this.unit = '元';
            }else if(this.couponinfo.UseType == '2'){
                this.CouponValue = '优惠券折扣';
                this.unit = "%";
            }
        },
        ChangeCouponType(){
            console.log(this.couponinfo.CouponType);
            if(this.couponinfo.CouponType == 1){
                this.CouponValue = '优惠券面值';
            }else if(this.couponinfo.UseType == '2'){
                this.CouponValue = '优惠券面值';
            }
        },
        AddPlan(){
            this.addplan = true;
            this.GetPlanList();
        },
        SetBrand(){
            this.brand = "";
        },
        SetModel(){
            this.model = "";
        },
        GetModel(){
            this.loading = true;
            this.axios.get(constant.get_plan_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category:JSON.stringify(this.category),
                    brand:this.brand,
                    status:1,
                }
            }).then((response)=>{
                console.log(response.data);
                this.models = response.data;
                this.loading = false;
            });
        },
        GetPdt(){
            
            this.axios.get(constant.get_plan_pdt_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    status: 1,
                }
            }).then((response)=>{
                
                this.pdts = response.data;
                
            });
        },
        GetPlanList(){
            this.axios.get(constant.get_plan_list,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    ids: JSON.stringify(this.selectid),
                    category: JSON.stringify(this.category),
                    brand: this.brand,
                    model: this.model,
                    keyword: this.keyword,
                    pdt: this.pdt,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response)=>{
                console.log(response.data);
                console.log(response.data.list);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        init(){
            this.GetPlanList();
        },
        Remove(row){
            console.log(row);
            let index = row.$index;
            let id = row.row.ID;
            let id_index = this.selectid.indexOf(id);
            if(id_index != -1)
                this.selectid.splice(id_index,1);
            this.couponinfo.pdtdata.splice(index,1);
        },
        back(){
            this.$router.go(-1);
        }
    },
    created:function(){
        let id = this.$route.query.id;
        if(id){
            this.axios.get(constant.get_coupon_detail,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.couponinfo.ID = response.data.ID;
                this.couponinfo.CouponName = response.data.CouponName;
                this.couponinfo.Type = response.data.Type;
                this.couponinfo.CouponType = response.data.CouponType;
                this.couponinfo.UseType = response.data.UseType;
                this.couponinfo.Condition = response.data.CouponCondition;
                this.couponinfo.CouponValue = response.data.CouponValue*1;
                this.couponinfo.ConditionValue = response.data.ConditionVal*1;
                this.couponinfo.StartDTime = response.data.StartDTime;
                this.couponinfo.EndDTime = response.data.EndDTime;
                this.couponinfo.UseTimeType = response.data.UseTimeType;
                this.couponinfo.UseTime = response.data.UseTime*1;
                this.couponinfo.AvaliableDTime = response.data.AvaliableDTime;
                this.couponinfo.ExpireDTime = response.data.ExpireDTime;
                this.couponinfo.RecevieCount = response.data.RecevieCount;
                this.couponinfo.UseCondition = response.data.UseCondition;
                if(this.couponinfo.UseType == 2)
                    this.unit = "%";
                if(response.data.UseCondition.indexOf('2')!= -1)
                    this.showtimelimit = true;
                this.couponinfo.TimeLimit = response.data.TimeLimit;
                this.couponinfo.LimitStart = response.data.LimitStart;
                this.couponinfo.LimitEnd = response.data.LimitEnd;
                this.couponinfo.Range = response.data.UseRange;
                this.selectid = response.data.ids;
                this.couponinfo.selectid = response.data.ids;
                this.couponinfo.Remark = response.data.Remark;
                this.couponinfo.TypeOneMutual = response.data.TypeOneMutual;
                this.couponinfo.TypeTwoMutual = response.data.TypeTwoMutual;
                //console.log(this.couponinfo.selectid);
                if(this.couponinfo.selectid.length > 0){
                    this.axios.get(constant.get_sel_plan,{
                        headers:{
                            'Content-Type': 'application/json'
                        },
                        params:{
                            ids: JSON.stringify(response.data.ids),
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        this.couponinfo.pdtdata = response.data;
                    })
                }
                
            })
        }
        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.option = response.data;
        });

        this.axios.get(constant.get_brand_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params :{
                status : 1
            }
        }).then((response)=>{
            console.log(response.data);
            this.brands = response.data;
        });
    }
}
</script>